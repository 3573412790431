import React from "react";
import "./loaderIcon.scss";

interface Props {
  color?: string;
}

const LoaderIcon: React.FC<Props> = props => {
  return <div className="loader-icon"></div>
}

export default LoaderIcon;