import React from 'react';
import { useOptions } from 'hooks/useOptions.query';
import classNames from 'classnames';
import './actionBar.scss';
import parse from 'html-react-parser';

interface Props {
  onClose: () => void;
  isVisible: boolean;
}

const ActionBar: React.FC<Props> = (props) => {
  const options = useOptions();
  const data = options.wp.themeGeneralSettings.actionBar;

  return (
    <div
      className={classNames('action-bar', {
        'background-red': data.useRedBackground,
      })}
    >
      <div className="action-bar__wrapper">
        <div className="action-bar__desktop">{parse(data.textDesktop)}</div>
        <div className="action-bar__mobile">{parse(data.textMobile)}</div>
        <div className="action-bar__closed" onClick={props.onClose}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
