import { navigate } from 'gatsby-link';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

interface Props {
  to: string;
  className?: string;
  silent?: boolean;
  onClick?: (e: MouseEvent) => void;
  onAfterClick?: () => void;
  isActive?: boolean;
}

export const handlePageLoaded = () => {
  const el = document.getElementById('trans-layer');
  !!el &&
    gsap.to(el, {
      opacity: 0,
      duration: 0.3,
      ease: 'power2.in',
      delay: 0,
    });
};

export const TransLink: React.FC<Props> = (props) => {
  const location = useLocation();

  const handleClick = (e: MouseEvent) => {
    // Default behavior for mailto links
    if (props.to.startsWith('mailto:')) {
      return;
    }

    e.preventDefault();

    if (location.pathname === props.to) {
      !!props.onAfterClick && props.onAfterClick();
      return;
    }

    if (props.silent) {
      navigate(props.to);
      return;
    }

    const el = document.getElementById('trans-layer');
    if (!!el) {
      el.style.setProperty('transform-origin', 'top left');
      gsap.to(el, {
        opacity: 1,
        duration: 0.3,
        ease: 'power2.out',
        onComplete: () => {
          navigate(props.to);
        },
      });
    } else {
      navigate(props.to);
    }

    !!props.onAfterClick && props.onAfterClick();
  };

  return (
    <a
      href={props.to}
      className={classNames(props.className, {
        'is-active': location.pathname.match(props.to) || props.isActive,
      })}
      onClick={props.onClick || handleClick}
    >
      {props.children}
    </a>
  );
};
