import React, { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";
import Logo from "assets/iwp-logo.svg";

import "./loader.scss";
import classNames from "classnames";
import LoaderIcon from "./loaderIcon";

enum LOADER_STATE {
  IDLE = "idle",
  INIT = "init",
  LOADING = "loading",
  LOAD_FONT = "load-font",
  COMPLETE = "complete",
}

interface Props {}

const Loader: React.FC<Props> = (props) => {
  const [state, setState] = useState<LOADER_STATE>(LOADER_STATE.INIT);
  const [fontLoaded, setFontLoaded] = useState<boolean>(false);

  useEffect(() => {
    const loadFont = () => {
      const font = new FontFaceObserver("Baskerville10Pro");
      font
        .load()
        .then(() => {
          loadFont2();
        })
        .catch(() => {
          loadFont2();
        });
    };

    const loadFont2 = () => {
      const font = new FontFaceObserver("Arimo");
      font
        .load()
        .then(() => {
          setFontLoaded(true);
        })
        .catch(() => {
          setFontLoaded(true);
        });
    };

    switch (state) {
      case LOADER_STATE.INIT:
        setState(LOADER_STATE.LOADING);
        break;

      case LOADER_STATE.LOADING:
        if (!fontLoaded) {
          loadFont();
        } else {
          setState(LOADER_STATE.COMPLETE);
        }
        break;
    }
  }, [state, fontLoaded]);

  return (
    <div
      className={classNames("loader", {
        "is-visible":
          state !== LOADER_STATE.IDLE && state !== LOADER_STATE.COMPLETE,
      })}
    >
      <div className="loader-inner">
        <LoaderIcon />
      </div>
    </div>
  );
};

export default Loader;
