// https://www.wpgraphql.com/docs/menus/

import { useStaticQuery, graphql } from "gatsby";

export const useLegalMenu = () => {
  const data = useStaticQuery(
    graphql`
      query LegalMenu {
        menu: wpMenu(name: { eq: "Legal Menu" }) {
          menuItems {
            nodes {
              id
              label
              path
              target
              cssClasses
              parentId
            }
          }
        }
      }
    `
  );
  return data;
};
