import React from "react";
import "./transLayer.scss";
import LoaderIcon from "components/loader/loaderIcon";

interface Props {}

const TransLayer: React.FC<Props> = (props) => {
  return (
    <div className="trans-layer" id="trans-layer">
      <div className="trans-layer__loading">
        <LoaderIcon />
      </div>
    </div>
  );
};

export default TransLayer;
