import React, { useState } from 'react';
import parse from 'html-react-parser';
import './footer.scss';
import { useMainMenu } from 'hooks/useMainMenu.query';
import { useLegalMenu } from 'hooks/useLegalmenu.query';
import { menuItemProps } from 'utils/props.utils';
import Logo from 'assets/iwp-logo.svg';
import { flatListToHierarchical } from 'utils/navHelper.utils';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { TransLink } from 'components/trans/transLink';
import IconYoutube from 'assets/icon-youtube.svg';
import IconLinkedin from 'assets/icon-linkedin.svg';

const Footer: React.FC = () => {
  const location = useLocation();
  const mainMenu = useMainMenu();
  const legalMenu = useLegalMenu();

  const [openSubmenuId, setOpenSubmenuId] = useState<string | null>(null);
  const toggleSubMenu = (submenuId: string) => {
    const isAlreadyOpen = submenuId === openSubmenuId;
    const id = isAlreadyOpen ? null : submenuId;
    setOpenSubmenuId(id);
  };

  const year = new Date().getFullYear();

  return (
    <div className="footer">
      {/* ADRESS */}
      <div className="footer-address">
        <Logo className="footer-address__logo" />
        <div className="footer-address__address">
          <div>Institut für Schweizer Wirtschaftspolitik</div>
          <div>an der Universität Luzern</div>
          <div>Obergrundstrasse 9</div>
          <div>6003 Luzern</div>
        </div>
      </div>

      <div>
        {/* MAIN NAV */}
        <div className="footer-main-nav">
          <ul className="footer-main-nav__row">
            {flatListToHierarchical(mainMenu.menu.menuItems.nodes).map((item: any, key: number) => {
              const isSubmenuOpen = openSubmenuId === item.id;
              const isActive = !!item.children && item.children.filter((subItem: any) => location.pathname.match(subItem.path)).length;

              return (
                <li className={classNames('footer-main-nav__item', { 'is-open': isSubmenuOpen })} key={key}>
                  {/* Items without sub items should are links */}
                  {item.children?.length > 0 ? (
                    <span className={classNames('footer-main-nav__item-top', { 'is-active': isActive })} onClick={() => toggleSubMenu(item.id)}>
                      <span>{item.label}</span>
                      <div className="footer-main-nav__item-top-arrow"></div>
                    </span>
                  ) : (
                    <a href={item.path} className="footer-main-nav__item-top">
                      {item.label}
                    </a>
                  )}

                  {!!item.children && (
                    <ul
                      className="footer-main-nav__sub-container"
                      style={{ height: isSubmenuOpen ? `${item.children.length * 40 + 10}px` : undefined }}
                    >
                      {item.children.map((subItem: any, subKey: any) => (
                        <li key={subKey}>
                          <a
                            href={subItem.path}
                            className={classNames('footer-main-nav__item-sub', {
                              'is-active': location.pathname.match(subItem.path),
                            })}
                          >
                            {subItem.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        {/* BUTTONS */}
        <div className="footer-buttons">
          <TransLink to="/newsletter">Newsletter abonnieren</TransLink>
          <TransLink to="/spenden">Spenden & Unterstützen</TransLink>
        </div>

        {/* LEGAL */}
        <div className="footer-legal">
          <div className="footer-legal__copyright">{`${parse('&copy')} ${year} Institut für Schweizer Wirtschaftspolitik`}</div>
          <ul>
            {legalMenu.menu.menuItems.nodes.map((item: menuItemProps, key: number) => {
              return (
                <li key={key}>
                  <a href={item.path} target={item.target || '_self'}>
                    {item.label}
                  </a>
                </li>
              );
            })}
            <li>
              <button onClick={() => window.Cookiebot?.show()}>Cookie Einstellungen</button>
            </li>
            <li className="footer-icons">
              <ul>
                <li>
                  <a href="https://www.youtube.com/channel/UCCGBJJ-rH8l-arjfVbT4kbA" target="_blank" rel="noopener noreferrer">
                    <IconYoutube />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/iwp-swiss/" target="_blank" rel="noopener noreferrer">
                    <IconLinkedin />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
