import { useMainMenu } from 'hooks/useMainMenu.query';
import { flatListToHierarchical } from 'utils/navHelper.utils';
import React, { useEffect, useRef, useState } from 'react';
import './menu.scss';
import MenuItem from './menuItem';
import Logo from 'assets/iwp-logo.svg';
import { TransLink } from 'components/trans/transLink';
import Hamburger from 'components/hamburger/hamburger';
import classNames from 'classnames';
import { useLocation } from '@reach/router';
import IconSID from '../../assets/button-sid.svg';
import { useOptions } from 'hooks/useOptions.query';
import parse from 'html-react-parser';

interface Props {
  pageContext: {
    singlePost?: boolean;
  };
}

const Menu: React.FC<Props> = (props) => {
  const dummyMobileRef = useRef<HTMLDivElement>(null);
  const mainMenuRaw = useMainMenu();
  const mainMenu = flatListToHierarchical(mainMenuRaw.menu.menuItems.nodes);
  const [idSubOpen, setIdSubOpen] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const location = useLocation();
  const showCTA = true;
  const options = useOptions();
  const [subNavHeight, setSubNavHeight] = useState<number>(0);

  useEffect(() => {
    const _isMobile = !!dummyMobileRef.current && dummyMobileRef.current.style.display !== 'none';
    if (_isMobile) {
      if (menuOpen) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  }, [menuOpen]);

  useEffect(() => {
    setIdSubOpen(null);
  }, [menuOpen]);

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      const dummy = dummyMobileRef.current;
      if (!!dummy) {
        const _isMobile = getComputedStyle(dummy).display !== 'none';
        setIsMobile(_isMobile);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const stoererData = options.wp.themeGeneralSettings.stoerer;

  return (
    <div className={classNames('menu', { 'is-open': menuOpen, 'is-cta': showCTA, 'is-sub-open': !!idSubOpen })}>
      <div className="menu-inner">
        <div className="menu-wrapper">
          {/* LOGO */}
          <div className="menu-top">
            <div className="menu-logo">
              <TransLink to="/">
                <Logo />
              </TransLink>
            </div>
            <button className="menu-burger" onClick={() => setMenuOpen(!menuOpen)}>
              <div className="menu-burger__title">Menu</div>
              <Hamburger isOpen={menuOpen} />
            </button>
          </div>

          {/* NAV */}
          <div className="menu-nav">
            <ul className="menu-nav__list">
              {mainMenu.map((item, key) => (
                <MenuItem
                  {...item}
                  key={key}
                  onMasterHover={(id, subHeight) => {
                    setIdSubOpen(id);
                    setSubNavHeight(subHeight ?? 0);
                  }}
                  onMasterLeave={(id) => {
                    if (id === idSubOpen) {
                      setIdSubOpen(null);
                      setSubNavHeight(0);
                    }
                  }}
                  isSubOpen={item.id === idSubOpen}
                  onMenuClose={() => setMenuOpen(false)}
                  pageContext={props.pageContext}
                  isMobile={isMobile}
                />
              ))}
            </ul>
          </div>

          {/* STOERER */}
          <div className="menu-stoerer-container">
            {!!stoererData.displayStorer && (
              <div className="menu-stoerer">
                {!!stoererData.internalLink ? (
                  <TransLink to={stoererData.internalLink.link}> {parse(stoererData.stoererText)}</TransLink>
                ) : (
                  <a href={stoererData.externalLink} target="_blank" rel="noreferrer">
                    {parse(stoererData.stoererText)}
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="menu-dummy-mobile" ref={dummyMobileRef} />
      </div>
      <div className="menu-sub-area" style={{ height: subNavHeight }} />
    </div>
  );
};

export default Menu;
