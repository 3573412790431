import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TransLink } from 'components/trans/transLink';

import './menuItem.scss';
import { useLocation } from '@reach/router';

interface MenuItemProps {
  id: string;
  label: string;
  path: string;
  target: string;
  children: MenuItemProps[];
  pageContext: {
    singlePost?: boolean;
  };
  connectedNode?: {
    node: {
      isPostsPage: boolean;
    };
  };
  isMobile?: boolean;
}

interface Props extends MenuItemProps {
  isSubOpen?: boolean;
  onMasterHover?: (id: string, subHeight?: number) => void;
  onMasterLeave?: (id: string) => void;
  onMenuClose?: () => void;
}

const MenuItem: React.FC<Props> = (props) => {
  const [subHeight, setSubHeight] = useState<number>(0);
  const subListRef = useRef<HTMLUListElement>(null);
  const location = useLocation();

  const handleMasterClick = (e: MouseEvent) => {};

  useEffect(() => {
    const handleResize = () => {
      !!subListRef.current && setSubHeight(subListRef.current.clientHeight);
    };
    setTimeout(handleResize, 500); // Add delay because clientHeight might be incorrect on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const childrenActive = props.children.filter((el) => location.pathname.match(el.path));

  let isActive = false;
  props.children.forEach((item) => {
    if (!!item.connectedNode && props.pageContext.singlePost && item.connectedNode.node.isPostsPage) {
      isActive = true;
    }
  });

  return (
    <li
      className={classNames('menu-item', { 'is-subnav-open': props.isSubOpen })}
      onMouseOver={() => !!props.children.length && !!props.onMasterHover && !props.isMobile && props.onMasterHover(props.id, subHeight)}
      onMouseLeave={() => !!props.children.length && !!props.onMasterLeave && !props.isMobile && props.onMasterLeave(props.id)}
      onClick={() => {
        if (!!props.children.length && !!props.onMasterHover && !!props.onMasterLeave && !!props.isMobile) {
          if (!props.isSubOpen) {
            props.onMasterHover(props.id);
          } else {
            props.onMasterLeave(props.id);
          }
        }
      }}
    >
      {!!props.children.length ? (
        <a
          href="#"
          onClick={handleMasterClick}
          className={classNames('menu-item__link', 'is-first-level', {
            'is-active': !!childrenActive.length || isActive,
            'is-active-subnav': props.isSubOpen,
          })}
        >
          <span>{props.label}</span>
          <div className="menu-item__link-arrow" />
        </a>
      ) : (
        <TransLink to={props.path} className="menu-item__link is-first-level" isActive={isActive} onAfterClick={props.onMenuClose}>
          <span>{props.label}</span>
        </TransLink>
      )}
      {/* SUBNAV */}
      {props.children && (
        <div
          className="menu-item-subnav"
          style={{
            height: props.isSubOpen ? `${subHeight}px` : '0',
            transitionDuration: `${props.children.length * 0.1}s`,
          }}
        >
          <ul className="menu-item-subnav__menu" ref={subListRef}>
            {props.children.map((item, key) => {
              let isSubActive = false;
              if (!!item.connectedNode && props.pageContext.singlePost && item.connectedNode.node.isPostsPage) {
                isSubActive = true;
              }
              return (
                <li key={key} className="menu-item__item">
                  <TransLink
                    to={item.path}
                    className="menu-item__link"
                    isActive={isSubActive}
                    onAfterClick={props.onMenuClose}
                    // onClick={props.onMenuClose}
                  >
                    <span>{item.label}</span>
                  </TransLink>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
};

export default MenuItem;
