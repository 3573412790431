exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-cookiebot-cookiebot-template-tsx": () => import("./../../../src/templates/cookiebot/cookiebot.template.tsx" /* webpackChunkName: "component---src-templates-cookiebot-cookiebot-template-tsx" */),
  "component---src-templates-donation-donation-template-tsx": () => import("./../../../src/templates/donation/donation.template.tsx" /* webpackChunkName: "component---src-templates-donation-donation-template-tsx" */),
  "component---src-templates-events-events-template-tsx": () => import("./../../../src/templates/events/events.template.tsx" /* webpackChunkName: "component---src-templates-events-events-template-tsx" */),
  "component---src-templates-facts-facts-template-tsx": () => import("./../../../src/templates/facts/facts.template.tsx" /* webpackChunkName: "component---src-templates-facts-facts-template-tsx" */),
  "component---src-templates-frontpage-frontpage-template-tsx": () => import("./../../../src/templates/frontpage/frontpage.template.tsx" /* webpackChunkName: "component---src-templates-frontpage-frontpage-template-tsx" */),
  "component---src-templates-glossary-glossary-template-tsx": () => import("./../../../src/templates/glossary/glossary.template.tsx" /* webpackChunkName: "component---src-templates-glossary-glossary-template-tsx" */),
  "component---src-templates-mediathek-mediathek-template-tsx": () => import("./../../../src/templates/mediathek/mediathek.template.tsx" /* webpackChunkName: "component---src-templates-mediathek-mediathek-template-tsx" */),
  "component---src-templates-page-page-template-tsx": () => import("./../../../src/templates/page/page.template.tsx" /* webpackChunkName: "component---src-templates-page-page-template-tsx" */),
  "component---src-templates-paper-paper-template-tsx": () => import("./../../../src/templates/paper/paper.template.tsx" /* webpackChunkName: "component---src-templates-paper-paper-template-tsx" */),
  "component---src-templates-post-post-template-tsx": () => import("./../../../src/templates/post/post.template.tsx" /* webpackChunkName: "component---src-templates-post-post-template-tsx" */),
  "component---src-templates-publications-publications-template-tsx": () => import("./../../../src/templates/publications/publications.template.tsx" /* webpackChunkName: "component---src-templates-publications-publications-template-tsx" */),
  "component---src-templates-sid-sid-health-template-tsx": () => import("./../../../src/templates/sid/sid-health.template.tsx" /* webpackChunkName: "component---src-templates-sid-sid-health-template-tsx" */),
  "component---src-templates-sid-sid-template-tsx": () => import("./../../../src/templates/sid/sid.template.tsx" /* webpackChunkName: "component---src-templates-sid-sid-template-tsx" */)
}

