// https://www.wpgraphql.com/docs/menus/

import { useStaticQuery, graphql } from "gatsby";

export const useMainMenu = () => {
  const data = useStaticQuery(
    graphql`
      query MainMenu {
        menu: wpMenu(name: { eq: "Main Menu" }) {
          menuItems {
            nodes {
              id
              label
              path
              target
              cssClasses
              parentId
              connectedNode {
                node {
                  ... on WpPage {
                    isPostsPage
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data;
};
