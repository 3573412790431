// https://www.wpgraphql.com/docs/menus/

import { useStaticQuery, graphql } from 'gatsby';

export const useOptions = () => {
  const data = useStaticQuery(
    graphql`
      query ThemeOptions {
        wp {
          themeGeneralSettings {
            footer {
              address
              copyright
              newsletterText
            }
            theme {
              headlessRedirectUrl
              share {
                mailSubject
                mailBody
                twitterText
              }
            }
            actionBar {
              textMobile
              textDesktop
              showActionBar
              useRedBackground
            }
            stoerer {
              displayStorer
              stoererText
              internalLink {
                ... on WpPage {
                  link
                }
                ... on WpPost {
                  link
                }
              }
              externalLink
            }
          }
        }
      }
    `
  );
  return data;
};
