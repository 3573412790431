import classNames from 'classnames';
import React from 'react';

import './hamburger.scss';

interface Props {
  className?: string;
  isOpen: boolean;
}

const Hamburger: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames(props.className, 'hamburger', 'hamburger--slider', {
        'is-active': props.isOpen,
      })}
      aria-label="Menu"
    >
      <span className="hamburger-inner"></span>
    </div>
  );
};

export default Hamburger;
