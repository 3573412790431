import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
//
import './layout.scss';
import Menu from 'components/menu/menu';
import Footer from 'components/footer/footer';
import TransLayer from 'components/trans/transLayer';
import Loader from 'components/loader/loader';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import moment from 'moment';
import useWinHeight from 'hooks/useWinHeight';
import ActionBar from 'components/actionBar/actionBar';
import { useOptions } from 'hooks/useOptions.query';
import Cookies from 'js-cookie';
import { useLocation } from '@reach/router';

interface Props {
  pageContext: {
    slug: string;
    id: string;
    singlePost?: boolean;
  };
}

moment.locale('de', {
  // customizations.
  months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
});

export const isApp = () => (typeof location !== 'undefined' && location.search.includes('app=true')) || !!Cookies.get('is-app');

const Layout: React.FC<Props> = (props) => {
  const [showActionBar, setShowActionBar] = useState<boolean>(false);
  const [showUI, setShowUI] = useState<boolean>(false);

  useWinHeight();

  const location = useLocation();

  gsap.registerPlugin(ScrollToPlugin);

  const options = useOptions();

  // *****************
  // actionbar
  useEffect(() => {
    const showInOptions = options.wp.themeGeneralSettings.actionBar.showActionBar;
    setShowActionBar(!Cookies.get('action-bar-closed') && showInOptions);
  }, []);

  const handleCloseActionBar = () => {
    Cookies.set('action-bar-closed', 'true', { expires: 10 });
    setShowActionBar(false);
  };

  // *****************
  // show hide ui
  useEffect(() => {
    const _isApp = isApp();
    setShowUI(!_isApp);
    _isApp ? Cookies.set('is-app', 'true') : Cookies.remove('is-app');
  }, [location]);

  return (
    <div className={classNames('layout', { 'is-action-bar-visible': showActionBar, 'is-app': !showUI })}>
      <Loader />
      {showActionBar && <ActionBar isVisible={showActionBar} onClose={handleCloseActionBar} />}
      {showUI && <Menu pageContext={props.pageContext} />}
      <TransLayer />
      <main className="layout-main">{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
